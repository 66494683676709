import { BaseResponse, C6BaseResponse, C6PageRequest, C6PageResponse, PageRequest } from '@/types/API'
import request, { c6Request } from '@/utils/request'

// /** 获取分账分组列表 */
// export function getLedgerGroupList(params: PageRequest<AnyObject>) {
//   return request.get<BaseResponse>('fund/ledger-group', { params })
// }

/** 获取分账分组列表 */
export function getLedgerGroupList(params: C6PageRequest<LedgerGroupQueryParams>) {
  return c6Request.get<C6BaseResponse<C6PageResponse>>('backstage/ledger/getLedgerGroupPage', { params })
}

// /** 新增分账分组 */
// export function addLedgerGroup(data: AnyObject) {
//   return request.post<BaseResponse>(`fund/ledger-group`, data)
// }

/** 新增分账分组 */
export function addLedgerGroup(data: AddLedgerGroupData) {
  return c6Request.post<C6BaseResponse>(`backstage/ledger/addLedgerGroup`, data)
}

// /** 根据分账分组id查询分账规则列表 */
// export function getLedgerListByGroupId(groupId: number) {
//   return request.get<BaseResponse>(`fund/ledger-list-by-group-id/${groupId}`)
// }

/** 根据分账分组id查询分账规则列表 */
export function getLedgerListByGroupId(groupId: number) {
  return c6Request.get<C6BaseResponse>(`backstage/ledger/getLedgerRulesByGroupId`, { params: { groupId } })
}

// /** 批量修改或新增规则 */
// export function batchAddLedger(dataList: AnyArray) {
//   return request.post<BaseResponse>(`fund/batch-add-ledger`, dataList)
// }

/** 批量修改或新增规则 */
export function batchAddLedger(dataList: AnyArray) {
  return c6Request.post<C6BaseResponse>(`backstage/ledger/batchAddOrUpdateLedgerRules`, dataList)
}

// /** 删除规则 */
// export function deleteLedger(ledgerId: number) {
//   return request.post<BaseResponse>(`fund/del-ledger/${ledgerId}`)
// }

/** 删除规则 */
export function deleteLedger(ledgerId: number) {
  return c6Request.post<C6BaseResponse>(`backstage/ledger/deleteLedgerRule`, ledgerId, { headers: {"Content-Type": "application/json"} })
}

// /** 设置旋切机主分账分组 */
// export function updateXQJZLedgerGroup(xqjzId: number, groupId: number) {
//   return request.post<BaseResponse>(`fund/update-xqjz-ledger-group/${xqjzId}/${groupId}`)
// }

/** 设置旋切机主分账分组 */
export function updateXQJZLedgerGroup(xqjzId: number, ledgerGroupId: number) {
  return c6Request.post<C6BaseResponse>(`backstage/ledger/updateXqjLedgerGroup`, { xqjzId, ledgerGroupId })
}

// /** 获取旋切机主分账分组 */
// export function getXQJZAccountList(params: PageRequest<AnyObject>) {
//   return request.get<BaseResponse>(`fund/xqjz-account-list`, { params })
// }

/** 获取旋切机主分账分组 */
export function getXQJZAccountList(params: C6PageRequest<XqjLedgerGroupQueryParams>) {
  return c6Request.get<C6BaseResponse<C6PageResponse>>(`backstage/ledger/getXqjLedgerGroupPage`, { params })
}

// /** 根据旋切机主id查询规则及账户列表 */
// export function getXQJZAccountDetail(xqjzId: number) {
//   return request.get<BaseResponse>(`fund/xqjz-account-detail/${xqjzId}`)
// }

/** 根据旋切机主id查询规则列表 */
export function getXqjzLedgerAccountInfo(xqjzId: number) {
  return c6Request.get<C6BaseResponse>(`backstage/ledger/getXqjLedgerAccountInfo`, { params: { xqjzId } })
}

/** 根据旋切机主id查询规则及账户列表 */
export function getXqjzBankCardInfo(xqjzId: number) {
  return c6Request.get<C6BaseResponse>(`backstage/ledger/getXqjBankCardInfo`, { params: { xqjzId } })
}

export interface LedgerGroupQueryParams {
  groupName?: string
}

export interface AddLedgerGroupData {
  groupDesc?: string,
  groupName: string
}

export interface XqjLedgerGroupQueryParams {
  groupName?: string,
  xqjzName?: string
}
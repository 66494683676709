
import { defineComponent, onActivated, reactive, ref, toRef } from 'vue'
import BasicTablePage from '@/components/BasicPageLayout/BasicTablePage.vue'
import BasicTablePrintAndExport from '@/components/BasicPageLayout/BasicTablePrintAndExport.vue'
import AccountSplitDetail from '@/views/ledger/LedgerAccountDetail.vue'
import usePagination from '@/utils/composables/usePagination'
import { Buttons, Inputs, SpanMethod, TableColumns } from '@/types/BasicComponent'
import { getOrderFlowList, QueryOrderFlowListParams } from '@/api/order'
import { formatTime, formatDate, getAreaListOfGx, getTableColumnsSummary } from '@/utils/common'
import dayjs from 'dayjs'

export default defineComponent({
  components: {
    AccountSplitDetail,
    BasicTablePage,
    BasicTablePrintAndExport
  },
  setup() {
    const pagination = usePagination()
    const searchData = reactive<QueryOrderFlowListParams>({
      startTime: dayjs().subtract(1, 'months').toDate(),
      endTime: dayjs().toDate(),
      orderNo: '',
      xqjzName: '',
      jhbcName: '',
      areaCode: ''
    })
    const tableData = ref<AnyArray>([])
    const gxAreaList = reactive<AnyArray>([])
    
    onActivated(() => {
      getTableData()
      getAreaListOfGx(gxAreaList) /*已在函数内部添加子项*/
    })

    const getTableData = async () => {
      tableData.value = []
      const resp = await getOrderFlowList(Object.assign({}, searchData, { pageNo: pagination.currentPage, pageSize: pagination.pageSize }))
      const respData = resp.data.data
      const respDataList = respData.records
      respDataList.slice().forEach((item: AnyObject, index: number) => {

        item.flowList.forEach((bp: AnyObject) => {
          tableData.value.push(Object.assign({ 
            idx: pagination.pageOffset + index, 
            orderNo: item.orderNo, 
            xqjzName: item.xqjzName, 
            jhbcName: item.jhbcName,
            payType: item.payType
          }, bp))
        })
      })
      pagination.total = respData.total
    }
    pagination.setCallback(getTableData)

    const spanMethod: SpanMethod = ({ row, column, rowIndex, columnIndex }) => {
      // 需要合并的列：0序号 1货单号 2旋切机主 3胶合板厂
      const columnsToSetRowSpan = [0, 1, 2]
      if (columnsToSetRowSpan.indexOf(columnIndex) >= 0) {
        if (rowIndex === 0 || row.orderNo !== tableData.value[rowIndex - 1].orderNo) {
          // 如果这行的orderNo和上一行的不一样，则需要合并行
          const rowSpanNumber = tableData.value.filter(d => d.orderNo === row.orderNo).length
          return {
            rowspan: rowSpanNumber,
            colspan: 1
          }
        } else {
          return {
            rowspan: 0,
            colspan: 0
          }
        }
      }
      return {
        rowspan: 1,
        colspan: 1
      }
    }
    const searchInputs = reactive<Inputs>([
      {
        label: '货单号',
        type: 'input',
        model: toRef(searchData, 'orderNo'),
        clearable: true
      }, {
        label: '旋切机主',
        type: 'input',
        model: toRef(searchData, 'xqjzName'),
        clearable: true
      }, {
        label: '胶合板厂',
        type: 'input',
        model: toRef(searchData, 'jhbcName'),
        clearable: true
      }, {
        label: '开始时间',
        type: 'date',
        clearable: false,
        model: toRef(searchData, 'startTime'),
        width: '150px'
      }, {
        label: '结束时间',
        type: 'date',
        clearable: false,
        model: toRef(searchData, 'endTime'),
        width: '150px'
      }, {
        label: '区域',
        type: 'select',
        placeholder: '请选择区域',
        clearable: true,
        options: gxAreaList,
        width: '120px',
        model: toRef(searchData, 'areaCode')
      }
    ])
    
    // 导出
    const exportRef = ref()
    const accountSplitDetailRef = ref()
    const searchButtons = reactive<Buttons>([
      {
        label: '查询',
        icon: 'el-icon-search',
        onClick: getTableData
      }, {
        label: '导出',
        icon: 'el-icon-download',
        onClick: () => {
          exportRef.value.doExport()
        }
      }
    ])

    const tableColumns = reactive<TableColumns>([
      {
        prop: 'idx',
        label: '序号',
        width: '70px',
        align: 'center',
      }, {
        prop: 'orderNo',
        label: '货单号',
        minWidth: '200px',
        type: 'render',
        align: 'center',
        preventFormat: true,
        render: scope => scope.row.payType === 2? <span style={{color: '#909399'}}>提现无法追踪订单号</span>: scope.row.orderNo
      }, {
        prop: 'xqjzName',
        label: '旋切机主',
        minWidth: '200px',
      }, {
        prop: 'jhbcName',
        label: '胶合板厂',
        minWidth: '200px',
      }, {
        prop: 'serialno',
        label: '支付序列号',
        minWidth: '230px',
        preventFormat: true,
      }, {
        prop: 'bankaccountno',
        label: '收款银行卡号',
        minWidth: '200px',
        preventFormat: true,
      }, {
        prop: 'bankaccountname',
        label: '收款人',
        minWidth: '140px',
      }, {
        prop: 'idcard',
        label: '收款人身份证号',
        minWidth: '200px',
        preventFormat: true,
      }, {
        prop: 'money',
        label: '支付金额(元)',
        minWidth: '140px',
      }, {
        type: 'render',
        prop: 'modifytime',
        label: '支付时间',
        minWidth: '200px',
        preventFormat: true,
        render: val => formatTime(val.row.modifytime)
      }, {
        prop: 'paytype',
        label: '支付类型',
        minWidth: '100px',
        type: 'status',
        align: 'center',
        statusType: [
          { value: 1, label: '订单', type: 'success' },
          { value: 2, label: '提现', type: 'warning' }
        ]
      }, {
        type: 'status',
        prop: 'paystatus',
        label: '支付状态',
        width: '110px',
        align: 'center',
        statusType: [
          { value: 0, type: 'info', label: '待受理' },
          { value: 1, type: 'success', label: '受理成功' },
          { value: 2, type: 'primary', label: '受理中' },
          { value: 3, type: 'danger', label: '受理失败' },
          { value: 4, type: 'danger', label: '支付异常' },
        ]
      }, {
        prop: 'acceptresult',
        label: '受理结果',
        minWidth: '200px',
      }
    ])
    
    const mySummaryMethod = (scope: AnyObject) => {
      return getTableColumnsSummary(['money'], scope.columns, scope.data)
    }
    
    return {
      pagination,
      searchInputs,
      searchData,
      searchButtons,
      tableColumns,
      tableData,
      accountSplitDetailRef,
      exportRef,
      formatTime,
      formatDate,
      spanMethod,
      getTableData,
      mySummaryMethod
    }
  }
})

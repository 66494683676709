
import { computed, defineComponent, reactive, ref, toRef, watch } from "vue"
import BasicFormDialog from '@/components/BasicPageLayout/BasicFormDialog.vue'
import BasicInputGroup from '@/components/BasicPageLayout/BasicInputGroup.vue'
import { Buttons, FormItem, FormItems, TableColumns } from "@/types/BasicComponent"
import { getXqjzBankCardInfo, getXqjzLedgerAccountInfo } from "@/api/ledger"
import BasicTable from "@/components/BasicPageLayout/BasicTable.vue"
import { clearObject, copyObject, formatTime } from "@/utils/common"

export default defineComponent({
  components: {
    BasicFormDialog,
    BasicInputGroup,
    BasicTable
  },
  emits: ['on-success'],
  setup(props, { emit }) {
    const modalShow = ref(false)

    const xqjzId = ref(-1)
    const respData = reactive({ rules: [], bankCardList: [] })
    const show = async (id: number) => {
      clearObject(ledgerAccountData)
      clearObject(bankCardData)

      xqjzId.value = id

      const resp = await Promise.all([getXqjzLedgerAccountInfo(id), getXqjzBankCardInfo(id)])

      copyObject(ledgerAccountData, resp[0].data.data)
      copyObject(bankCardData, resp[1].data.data)

      modalShow.value = true
    }

    const ledgerAccountData = reactive<AnyArray>([])
    const ledgerAccountColumns = reactive<TableColumns>([
      {
        label: '子账户',
        prop: 'ledgerName',
        align: 'center'
      }, {
        label: '余额（元）',
        prop: 'balance',
        align: 'center'
      }, {
        label: '状态',
        prop: 'inRuleGroup',
        type: 'status',
        align: 'center',
        statusType: [
          { label: '已停用', value: 0, type: 'danger' },
          { label: '使用中', value: 1, type: 'success' }
        ]
      }
    ])

    const bankCardData = reactive<AnyArray>([])
    const bankCardColumns = reactive<TableColumns>([
      {
        label: '银行账户',
        prop: 'cardNumber',
        align: 'center'
      }, {
        label: '银行名称',
        prop: 'bankName',
        align: 'center'
      }, {
        label: '绑定时间',
        prop: 'createTime',
        align: 'center',
        type: 'render',
        render: scope => formatTime(scope.row.createTime)
      }
    ])

    const footerButtons = reactive<Buttons>([
      // {
      //   label: '保存',
      //   onClick: () => {
      //     ElMessage.success('保存成功')
      //     emit('on-success')
      //     modalShow.value = false
      //   }
      // },
      {
        label: '确定',
        onClick: () => modalShow.value = false
      }
    ])

    return {
      show,
      respData,
      footerButtons,
      modalShow,
      ledgerAccountData,
      ledgerAccountColumns,
      bankCardData,
      bankCardColumns
    }
  }
})

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AccountSplitDetail = _resolveComponent("AccountSplitDetail")!
  const _component_BasicTablePrintAndExport = _resolveComponent("BasicTablePrintAndExport")!
  const _component_BasicTablePage = _resolveComponent("BasicTablePage")!

  return (_openBlock(), _createBlock(_component_BasicTablePage, {
    searchInputs: _ctx.searchInputs,
    searchButtons: _ctx.searchButtons,
    tableColumns: _ctx.tableColumns,
    tableData: _ctx.tableData,
    tableSpanMethod: _ctx.spanMethod,
    pagination: _ctx.pagination,
    summaryMethod: _ctx.mySummaryMethod
  }, {
    dialog: _withCtx(() => [
      _createVNode(_component_AccountSplitDetail, {
        ref: "accountSplitDetailRef",
        onOnSuccess: _ctx.getTableData
      }, null, 8, ["onOnSuccess"]),
      _createVNode(_component_BasicTablePrintAndExport, {
        ref: "exportRef",
        tableColumns: _ctx.tableColumns,
        tableData: _ctx.tableData,
        fileName: `货单流水列表 ${_ctx.formatDate(_ctx.searchData.startTime)} 至 ${_ctx.formatDate(_ctx.searchData.endTime)}`,
        spanMethod: _ctx.spanMethod
      }, null, 8, ["tableColumns", "tableData", "fileName", "spanMethod"])
    ]),
    _: 1
  }, 8, ["searchInputs", "searchButtons", "tableColumns", "tableData", "tableSpanMethod", "pagination", "summaryMethod"]))
}